import { defineNuxtRouteMiddleware, setPageLayout } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to) => {
    // Dynamically set the layout for the route you are navigating to
    if (to.path === '/template1') {
        setPageLayout('template1')
    }
    if (to.path === '/homemaster') {
        setPageLayout('homemaster')
    }
})